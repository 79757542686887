import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"
import Layout from "../components/common/layout"
import Seo from "../components/common/seo"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { INLINES } from "@contentful/rich-text-types"
import { Link } from "gatsby"
// import ResourceCenter from "../pages/resource-centre/"
import ResourceCenter from "../components/resource-centre/resource-centre"
import "../../static/assets/css/resources-new.css"

const options = {
  renderNode: {
    [INLINES.HYPERLINK]: node => {
      if (node.data.uri.includes("youtube.com/embed")) {
        return (
          <iframe
            src={node.data.uri}
            allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
            frameBorder="0"
            allowFullScreen
            style={{ width: "100%", height: "400px" }}
          ></iframe>
        )
      } else if (node.data.uri.includes("https://")) {
        return (
          <a href={node.data.uri} target="_blank" rel="noreferrer">
            {node.content[0].value}
          </a>
        )
      } else {
        return (
          <Link to={node.data.uri}>
            {node.content[0].value}
          </Link>
        )
      }
    },
  },
}

const Resources = ({ data }) => {
  useEffect(() => {
    // Create a MutationObserver to watch for class changes on the body
    const observer = new MutationObserver((mutationsList) => {
      for (let mutation of mutationsList) {
        if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
          if (document.body.classList.contains('fr')) {
            const targetElement = document.querySelector('.res_common_list_ul.sidebar_menu_content.res_tab_list .tab-link:nth-child(2)');
            const targetElementOne = document.querySelector('.res_common_list_ul.sidebar_menu_content.res_tab_list .tab-link:nth-child(1)');
            targetElement.click();

          }
        }
      }
    });

    // Start observing the body for attribute changes (class changes)
    observer.observe(document.body, { attributes: true });

    // Cleanup the observer when the component is unmounted
    return () => {
      observer.disconnect();
    };
  }, []);


  const {
    title,
    description,
    item1,
    content1,
    item2,
    content2,
    item4,
    content4,
  } = data.contentfulResources

  const [idx, setIdx] = useState(0)

  useEffect(() => {
    const mainSection = document.querySelector(".resources_main_section")
    if (idx === 0) {
      mainSection.classList.add("resource-center-active")
    } else {
      mainSection.classList.remove("resource-center-active")
    }
  }, [idx])
  

  return (
    <div className="resourcesPage">
      <Layout>
        <Seo title="Resources | COMMB " />
        <div className="main_content_wrapper">
          <div className="resources_main_section">
            <div className="container">
              <div className="measurement_heading_section">
                <div className="resources_info_title">
                  {/* <h1>{heading}</h1> */}
                </div>
                <div className="common_desc">
                  {/* <p>{description.description}</p> */}
                </div>
              </div>
              <div className="resources_block">
                <div className="resources_left_col">
                  <div className="resources_sidebar_block sidebar_menu_main" id="sidebar">
                    <div className="resources_sidebar_content sidebar_menu_main_content">
                      <div>
                        <div className="res_common_sidebar_div">
                          <div className="res_common_title_box sidebar_menu_title sidebar_menu_open">
                            <h3>{item1}</h3>
                          </div>
                          <div className="res_common_list_ul sidebar_menu_content res_tab_list">
                            <ul className="tabs">
                              <li
                                className={`tab-link ${0 === idx ? 'active' : ''}`}
                                onClick={() => setIdx(0)}
                              >
                                <a href="#0">
                                  Resource Centre
                                </a>
                              </li>
                              {content1.map((item, i) => (
                                <li
                                  className={`tab-link ${item.id === idx ? 'active' : ''}`}
                                  data-tab={`tab-${item.id}`}
                                  key={item.id}
                                  onClick={() => setIdx(item.id)}
                                >
                                  <a href="#0">{item.listItem}</a>
                                </li>
                              ))}
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className="res_common_sidebar_div">
                          <div className="res_common_title_box sidebar_menu_title">
                            <h3>{item2}</h3>
                          </div>
                          <div className="res_common_list_ul sidebar_menu_content">
                            <ul>
                              {content2.map((item, i) => (
                                <li
                                  className={`tab-link ${item.id === idx ? 'active' : ''}`}
                                  data-tab={`tab-${item.id}`}
                                  key={item.id}
                                  onClick={() => setIdx(item.id)}
                                >
                                  <a href="#0">{item.listItem}</a>
                                </li>
                              ))}
                              {/* <li>
                                <a href="#0">Impact of Media Fragmentation</a>
                              </li>
                              */}
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className="res_common_sidebar_div">
                          <div className="res_common_title_box sidebar_menu_title">
                            <h3>{item4}</h3>
                          </div>
                          <div className="res_common_list_ul sidebar_menu_content">
                            <ul>
                              {content4.map((item, i) => (
                                <li
                                  className={`tab-link ${item.id === idx ? 'active' : ''}`}
                                  data-tab={`tab-${item.id}`}
                                  key={item.id}
                                  onClick={() => setIdx(item.id)}
                                >
                                  <a href="#0">{item.listItem}</a>
                                </li>
                              ))}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="resources_right_col cm_right_content measurement_right_col">
                  <div className="resources_info_content_block">
                    <div className="resources_info_title mobile-none">
                      <h1>{title}</h1>
                    </div>
                    <div className="common_desc mobile-none">
                      <p>{description.description}</p>
                    </div>

                    <div className="tabs_data">
                      <div className={`tab-content ${idx === 0 ? "active" : ""}`} id="resources-tab">
                        <ResourceCenter />
                      </div>
                      {content1.map((item, i) => {
                        return (
                          <div
                            className={`tab-content ${item.id === idx ? "active" : ""}`}
                            id={`tab-${item.id}`}
                            key={item.id}
                          >
                            <div className="download_study_here">
                              <div className="measurement_info_content_block">
                                <div className="commb_data_report">
                                  <p className="#0">{item.listItem}</p>
                                </div>
                                <div className="tab_content_wrapper">
                                  <div className="measurement_ooh_data measurement-richtext">
                                    {renderRichText(item.content, options)}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                      })}
                      {content2.map((item, i) => {
                        return (
                          <div
                            className={`tab-content ${item.id === idx ? "active" : ""}`}
                            id={`tab-${item.id}`}
                            key={item.id}
                          >
                            <div className="download_study_here">
                              <div className="measurement_info_content_block">
                                <div className="commb_data_report">
                                  <p className="#0">{item.listItem}</p>
                                </div>
                                <div className="tab_content_wrapper">
                                  <div className="measurement_ooh_data measurement-richtext">
                                    {renderRichText(item.content, options)}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                      })}
                      {content4.map((item, i) => {
                        return (
                          <div
                            className={`tab-content ${item.id === idx ? "active" : ""}`}
                            id={`tab-${item.id}`}
                            key={item.id}
                          >
                            <div className="download_study_here">
                              <div className="measurement_info_content_block">
                                <div className="commb_data_report">
                                  <p className="#0">{item.listItem}</p>
                                </div>
                                <div className="tab_content_wrapper">
                                  <div className="measurement_ooh_data measurement-richtext">
                                    {renderRichText(item.content, options)}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </div>
  )
}

export default Resources

export const query = graphql`
  query Resources($language: String) {
    contentfulResources(node_locale: { eq: $language }) {
      title
      description {
        description
      }
      item1
      content1 {
        id
        listItem
        content {
          raw
        }
      }
      item2
      content2 {
        id
        listItem
        content {
          raw
        }
      }
      item4
      content4 {
        id
        listItem
        content {
          raw
        }
      }
    }
  }
`
